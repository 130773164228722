@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("./mono/woff2/IBMPlexMono-Bold.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Bold.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("./mono/woff2/IBMPlexMono-BoldItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("./mono/woff2/IBMPlexMono-Light.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Light.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("./mono/woff2/IBMPlexMono-LightItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-LightItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medm"), local("IBMPlexMono-Medm"), url("./mono/woff2/IBMPlexMono-Medium.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Medium.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medm Italic"), local("IBMPlexMono-MedmItalic"), url("./mono/woff2/IBMPlexMono-MediumItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("./mono/woff2/IBMPlexMono-Regular.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Regular.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SmBld"), local("IBMPlexMono-SmBld"), url("./mono/woff2/IBMPlexMono-SemiBold.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SmBld Italic"), local("IBMPlexMono-SmBldItalic"), url("./mono/woff2/IBMPlexMono-SemiBoldItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("./mono/woff2/IBMPlexMono-Text.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Text.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("./mono/woff2/IBMPlexMono-TextItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-TextItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("./mono/woff2/IBMPlexMono-Thin.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-Thin.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("./mono/woff2/IBMPlexMono-ThinItalic.woff2") format("woff2"), url("./mono/woff/IBMPlexMono-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("./sans/woff2/IBMPlexSans-Bold.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Bold.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("./sans/woff2/IBMPlexSans-BoldItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtLt"), local("IBMPlexSans-ExtLt"), url("./sans/woff2/IBMPlexSans-ExtraLight.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtLt Italic"), local("IBMPlexSans-ExtLtItalic"), url("./sans/woff2/IBMPlexSans-ExtraLightItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("./sans/woff2/IBMPlexSans-Italic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Italic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("./sans/woff2/IBMPlexSans-Light.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Light.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("./sans/woff2/IBMPlexSans-LightItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-LightItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medm"), local("IBMPlexSans-Medm"), url("./sans/woff2/IBMPlexSans-Medium.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Medium.woff") format("woff");
}

/* @font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medm"), local("IBMPlexSans-Medm"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; } */

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medm Italic"), local("IBMPlexSans-MedmItalic"), url("./sans/woff2/IBMPlexSans-MediumItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("./sans/woff2/IBMPlexSans-Regular.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Regular.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SmBld"), local("IBMPlexSans-SmBld"), url("./sans/woff2/IBMPlexSans-SemiBold.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SmBld Italic"), local("IBMPlexSans-SmBldItalic"), url("./sans/woff2/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("./sans/woff2/IBMPlexSans-Text.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Text.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("./sans/woff2/IBMPlexSans-TextItalic.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-TextItalic.woff") format("woff");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("./sans/woff2/IBMPlexSans-Thin.woff2") format("woff2"), url("./sans/woff/IBMPlexSans-Thin.woff") format("woff");
}