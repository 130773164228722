*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

:before,
:after {
    --tw-content: ""
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.z-10 {
    z-index: 10
}

.-z-10 {
    z-index: -10
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.flex {
    display: flex
}

.h-screen {
    height: 100vh
}

.w-full {
    width: 100%
}

.w-72 {
    width: 18rem
}

.max-w-md {
    max-width: 28rem
}

.max-w-6xl {
    max-width: 72rem
}

.max-w-max {
    max-width: -moz-max-content;
    max-width: max-content
}

.flex-grow {
    flex-grow: 1
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.flex-col {
    flex-direction: column
}

.items-center {
    align-items: center
}

.justify-center {
    justify-content: center
}

.space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-opacity-50 {
    --tw-bg-opacity: .5
}

.bg-opacity-30 {
    --tw-bg-opacity: .3
}

.bg-opacity-40 {
    --tw-bg-opacity: .4
}

.p-4 {
    padding: 1rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.text-center {
    text-align: center
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.font-bold {
    font-weight: 700
}

.opacity-60 {
    opacity: .6
}

.duration-300 {
    transition-duration: .3s
}

html,
body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important
}

.auroral-agrabah {
    position: absolute;
    z-index: 1;
    left: -250%;
    top: -250%;
    width: 600%;
    height: 500%;
    animation-name: aurar;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    background: linear-gradient(-20deg, rgba(220, 127, 6, 0.654) 40%, rgba(2, 38, 156, 0.84)), linear-gradient(60deg, #ed721b53 10%, #06038d)
}

@keyframes aurar {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes aura {
    0% {
        transform: rotate(20deg)
    }

    20% {
        transform: translateY(100px) rotate(1deg)
    }

    40% {
        transform: rotate(-15deg)
    }

    60% {
        transform: rotate(-1deg)
    }

    80% {
        transform: translateY(-10px) rotate(16deg)
    }

    to {
        transform: rotate(1deg)
    }
}

.hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1
}

.group:hover .group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity))
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1
}

.lds-default.svelte-1t0xqd5.svelte-1t0xqd5 {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 24px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5 {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: svelte-1t0xqd5-lds-default 1.2s linear infinite
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(2) {
    animation-delay: -.1s;
    top: 22px;
    left: 62px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(3) {
    animation-delay: -.2s;
    top: 11px;
    left: 52px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(4) {
    animation-delay: -.3s;
    top: 7px;
    left: 37px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(5) {
    animation-delay: -.4s;
    top: 11px;
    left: 22px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(6) {
    animation-delay: -.5s;
    top: 22px;
    left: 11px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(7) {
    animation-delay: -.6s;
    top: 37px;
    left: 7px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(8) {
    animation-delay: -.7s;
    top: 52px;
    left: 11px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(9) {
    animation-delay: -.8s;
    top: 62px;
    left: 22px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(10) {
    animation-delay: -.9s;
    top: 66px;
    left: 37px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px
}

.lds-default.svelte-1t0xqd5 div.svelte-1t0xqd5:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px
}

@keyframes svelte-1t0xqd5-lds-default {

    0%,
    20%,
    80%,
    to {
        transform: scale(1)
    }

    50% {
        transform: scale(1.5)
    }
}